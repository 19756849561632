export const loginMessages = {
  login: {
    continueWith: 'Continuer avec',
    title: 'Nouveau projet full-stack',
    copyright: 'Copyright © Skloover',
    slogan: 'Apprends en pratiquant',
    login: 'Se connecter',
    linkRequested: 'Demande de connexion',
    checkEmail: "Un lien a été envoyé à l'adresse {email}",
    letsGo: "C'est parti !",
    changePassword: 'Changer le mot de passe',
    password: 'Mot de passe',
    newPassword: 'Nouveau mot de passe',
    confirmNewPassword: 'Confirmer le mot de passe',
    email: 'Email',
    firstName: 'Prénom',
    lastName: 'Nom',
    passwordMinError: '8 caractères minimum',
    passwordRegexError:
      '1 majuscule, 1 minuscule, 1 chiffre, 1 caractère special',
    emailError: 'Email invalide',
    nameError: 'Nom obligatoire',
    passwordMatchError: 'Les mots de passe ne correspondent pas',
    signinError: 'Email ou mot de passe incorrect',
    signupError: 'Une erreur est survenue',
    signup: 'Créer un compte',
    confirmSignup: "Confirmer l'email",
    confirmSignupCode: 'Code de vérification :',
    confirmSignupCodeError: '6 caractères',
  },
};
